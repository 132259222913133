<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                    
                    <router-link :to="'/outgoing/detail?id=' + props.row.id">
                        <a class="btn btn-sm  btn-primary text-white margin5">Detail</a>
                    </router-link>
                    <a v-on:click="resendOutgoing(props.row.id)" v-if="props.row.status == 'gagal' && props.row.note != 'Nomor tidak terdaftar'"  class="btn btn-sm btn-warning text-white margin5">
                        Kirim Ulang
                    </a>
                    <a v-on:click="deleteOutgoing(props.row.id)" class="btn btn-sm  btn-danger text-white margin5">
                        Hapus
                    </a>
                </span>
                <span v-else-if="props.column.field == 'text'">
                    <div v-if="props.row.message_type == 'text'">
                        {{ cutString(props.row.text) }}
                        <div v-if="props.row.file">
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <center>
                                        <b>Document</b>
                                        <br>
                                        <a :href="props.row.file" target="_blank"> Open File </a>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="props.row.message_type == 'button'">
                        <div class="card thick-border">
                            <div class="card-body">
                                <h6 style="text-align: center;">BUTTON MESSAGE</h6>
                                <hr>
                                {{ cutString(props.row.text) }}
                                <br>
                                <div v-if="props.row.file">
                                    <br>
                                    <div class="card">
                                        <div class="card-body">
                                            <center>
                                                <b>Document</b>
                                                <br>
                                                <a :href="props.row.file" target="_blank"> Open File </a>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div v-for="button in props.row.parsedButton" v-bind:key="button.index">
                                    <div class="card thick-border">
                                        <div class="card-body" style="padding:10px">
                                            <div v-if="button.button_type == 'quickReplyButton'">
                                                <!-- <p style="font-size:10px" class="float-right">[QUICK REPLY BUTTON]</p>
                                                <br> -->
                                                <h6 style="text-align:center">
                                                    {{ button.display_text }}
                                                </h6>
                                            </div>
                                            <div v-else-if="button.button_type == 'urlButton'">
                                                <!-- <p style="font-size:10px" class="float-right">[URL BUTTON]</p>
                                                <br> -->
                                                <h6 style="text-align:center">
                                                    <a :href="button.button_action" target="_blank"><i class="fas fa-globe fs-16"></i>&nbsp;{{ button.display_text }}</a>
                                                </h6>
                                            </div>
                                            <div v-else-if="button.button_type == 'callButton'">
                                                <!-- <p style="font-size:10px" class="float-right">[CALL BUTTON]</p>
                                                <br> -->
                                                <h6 style="text-align:center">
                                                    <a :href="'tel:' +button.button_action"><i class="fas fa-phone fs-16"></i>&nbsp;{{ button.display_text }}</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <a style="color:black;font-size: 12px;">{{ props.row.footer }}</a>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="props.row.message_type == 'list'">
                        <div class="card thick-border">
                            <div class="card-body">
                                <h6 style="text-align: center;">LIST MESSAGE</h6>
                                <hr>
                                <b>{{ props.row.parsedList.title }}</b>
                                <p>{{ cutString(props.row.text) }}</p>
                                <button class="btn btn-outline-success btn-block">{{ props.row.parsedList.buttonText }}</button>
                                <br>
                                <div v-for="s in props.row.parsedList.sections" v-bind:key="s.index" style="padding-top:10px">
                                    <div class="card thick-border">
                                        <div class="card-body" style="padding:5px">
                                            <b>{{ s.title }}</b>
                                            <p>{{ s.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <span v-else-if="props.column.field == 'jadwal'">
                    {{ convertDateToLocal(props.row.jadwal) }}
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.status == 'terkirim'" class="badge badge-success">Terkirim</span>
                    <span v-else-if="props.row.status == 'dalam antrian' || props.row.status == 'menunggu jadwal'"
                        class="badge badge-warning text-white">Dalam Pengiriman</span>
                    <span v-else-if="props.row.status == 'menunggu device terkoneksi'"
                        class="badge badge-warning text-white">Menunggu Device Terkoneksi</span>
                    <span v-else-if="props.row.status == 'gagal'" class="badge badge-danger">Gagal</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Tujuan",
                            field: "to",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Pesan",
                            field: "text",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "40%",
                        },
                        {
                            label: "Jadwal",
                            field: "jadwal",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Status",
                            field: "status",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    // serverParams: {
                    //     nextKey: null
                    // },
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            cutString(string){
                if(string == null || string == "") return ""
                
                if(string.length > 40){
                    string = string.slice(0, 40) + "..."
                }

                return string
            },
            convertDateToLocal(date) {
                return moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")
            },
            async deleteOutgoing(id) {
                try {
                    let response = await this.$store.dispatch("outgoing_delete", {
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            async resendOutgoing(id){
                try {
                    let response = await this.$store.dispatch("outgoing_resend", {
                        message_id: parseInt(id)
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    let outgoing = response.data.data.outgoings;
                    for (let i = 0; i < outgoing.length; i++) {
                        if(outgoing[i].message_type == "button"){
                            let parse = JSON.parse(outgoing[i].data)
                            outgoing[i].parsedButton = parse
                            outgoing[i].footer = parse?.[0].footer
                        }else if(outgoing[i].message_type == "list"){
                            let parse = JSON.parse(outgoing[i].data)
                            outgoing[i].parsedList = parse
                        }
                    }
                    this.table.rows = outgoing
                } catch (err) {
                    console.log(err)
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("broadcast_list", param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>