<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Kirim Pesan</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="messageQuota != -1 && messageQuota <= 10" class="alert alert-danger @endif " role="alert">
                <div class="alert-content">
                    <p>Sisa kuota pesan anda adalah <b>{{ messageQuota }}</b> pesan</p>
                </div>
            </div>
            <div v-else-if="messageQuota != -1 && messageQuota >= 10" class="alert alert-success @endif " role="alert">
                <div class="alert-content">
                    <p>Sisa kuota pesan anda adalah <b>{{ messageQuota }}</b> pesan</p>
                </div>
            </div>
            <div v-else-if="messageQuota == -1" class="alert alert-success @endif " role="alert">
                <div class="alert-content">
                    <p>Sisa kuota pesan anda adalah <b>Unlimited</b> pesan</p>
                </div>
            </div>

            <div class="alert alert-info" role="alert">
                <div class="alert-content">
                    <p>Tanggal & jam pengiriman mengikuti waktu lokal device | {{ now }} <b>UTC {{ timezone }}</b></p>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <div class="tab-wrapper">
                            <div class="atbd-tab tab-horizontal">
                                <ul class="nav nav-tabs vertical-tabs" role="tablist">
                                    <!-- <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.one }" v-on:click="changePage('one')">Kirim Pesan</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.two }" v-on:click="changePage('two')">Kirim Pesan</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.three }" v-on:click="changePage('three')">Kirim Dengan CSV</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <!-- <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                        <SendSingle :device="device"></SendSingle>
                                    </div> -->
                                    <div class="tab-pane fade" :class="{ 'show active': page.two }" id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                        <SendBlast :device="device"></SendBlast>
                                        <!-- <form enctype="multipart/form-data">
                                            <label for="">Pengirim</label>
                                            <select v-model="form.sender" class="form-control" required>
                                                <option value="" selected disabled>== PILIH DEVICE ==</option>
                                                <option v-for="d in device" :key="d.id" :value="d.id">{{ d.device_name }}</option>
                                            </select>
                                            <br>
                                            <label for="">Tujuan</label>
                                            <select name="to[]" class="to" multiple required>
                                            </select>
                                            <br>
                                            <br>
                                            <label for="">Text</label>
                                            <textarea class="form-control emoji" id="" cols="30" rows="10" name="text"></textarea>
                                            <br>
                                            <label> File (docx, xlsx, pdf, jpg, png, mp4) </label>
                                            <input type="file" name="file" class="form-control">
                                            <br>
                                            <label for="">Tanggal</label>
                                            <input type="text" class="form-control" name="tanggal" id="picker2" placeholder="dd/mm/yyyy" required>
                                            <br>
                                            <label for="">Jam</label>
                                            <input type="time" class="form-control" name="jam" id="picker" required>
                                            <br>
                                            <label for="">Delay Interval (detik)</label>
                                            <input type="number" class="form-control" name="delay" placeholder="kosongkan untuk delay random">
                                            <br>
                                            <i>- Anda bisa menggunakan dynamic string untuk generate random string, contoh : (halo|hai|pagi|siang) </i>
                                            <br>
                                            <i>- Anda bisa panggil nama menggunakan {nama} </i>
                                            <br>
                                            <i>- Anda bisa panggil variabel menggunakan {var1}, {var2}, {var3}, dst </i>
                                            <br>
                                            <br>
                                            <button class="btn btn-success btn-block">Kirim Pesan</button>
                                        </form> -->
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.three }" id="tab-v-3" role="tabpanel" aria-labelledby="tab-v-3-tab">
                                        <SendCSV :device="device"></SendCSV>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import SendSingle from "@/components/forms/outgoing/SendSingle.vue";
    import SendBlast from "@/components/forms/outgoing/SendBlast.vue";
    import SendCSV from "@/components/forms/outgoing/SendCSV.vue";

    import moment from 'moment'

    export default {
        components: {
            //SendSingle,
            SendBlast,
            SendCSV
        },
        data() {
            return {
                messageQuota: 0,
                form: {
                    sender: "",
                    receiver: "",
                    text: "",
                    file: "",
                    date: "",
                    time: "",
                    send_now: false,
                },
                page: {
                    one: false,
                    two: true,
                    three: false
                },
                device: [],
                timezone: "",
                now: "00:00:00",
                interval: null,
            };
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fnRefreshTable);
            this.$root.$off("toggle_modal", this.fnToggleModal);

            clearInterval(this.interval)
        },
        mounted() {
            this.interval = setInterval(() => {
                this.now = moment().format("YYYY-MM-DD HH:mm:ss")
            }, 1000)

            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fnRefreshTable = () => {
                this.fetchPacakge();
            }
            this.$root.$on("refresh_table", this.fnRefreshTable);
            

            this.getDevice({})
            this.getTimeZone()
            this.fetchPacakge()

            // this.messageQuota = this.$store.state.auth.package.message_quota
        },
        methods: {
            getTimeZone() {
                var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
                this.timezone = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
            async fetchPacakge() {
                try {
                    let p = await this.$store.dispatch('user_myPackage')
                    p = p.data.data.user_package
                    this.messageQuota = p.message_quota
                } catch (err) {
                    console.log(err)
                }
            },
            changePage(page){
                this.page.one = false
                this.page.two = false
                this.page.three = false

                if(page == "one") this.page.one = true
                if(page == "two") this.page.two = true
                if(page == "three") this.page.three = true
            }
        },
    };
</script>

<style scoped>
    .nav-link{
        cursor: pointer !important;
    }
</style>